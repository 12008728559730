import React from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { removeAlert } from '../../redux/actions/alert';

const AlertComponent = (props: any) => {

  const { color, className, icon, children, alertKey, removeAlert } = props;
  let Icon;

  const closeAlert = ( key: string, event: any ) => {
    event.preventDefault();
    removeAlert( key );
  };

  switch (color) {
    case 'info':
      Icon = <i className="fa-regular fa-circle-info"></i>;
      break;
    case 'success':
      Icon = <i className="fa-regular fa-circle-check"></i>;
      break;
    case 'warning':
      Icon = <i className="fa-regular fa-triangle-exclamation"></i>;
      break;
    case 'danger':
      Icon = <i className="fa-regular fa-circle-xmark"></i>;
      break;
    default:
      break;
  }

  return (
      <Alert color={color} className={className}>
        {icon && <div className={"alert__icon " + color} onClick={(e:any) => closeAlert( alertKey, e )}>{Icon}</div>}
        <div className="alert__content">
          {children}
        </div>
      </Alert>
  );

};

export default connect(null, { removeAlert })(AlertComponent);
