import React from 'react';
import SelectTable from '../../components/SelectTable';
import { connect } from 'react-redux';
import gears from '../../shared/img/gears.gif';

const BrowserTopicDetails = (props: any) => {

    const { browser } = props;

    const docsLoaded = browser && browser.categoryData && browser.categoryData.Name && browser.accomods && browser.accomods.length;

    const accomods:any = [];
    const accomodCats:any = [
        { category: 'Behavioral', key: 'B' },
        { category: 'Curriculum', key: 'C' },
        { category: 'Learning', key: 'L' },
        { category: 'Assessing', key: 'A' }
    ];

    let t:any = {};
    let a:any = [];

    if (docsLoaded) {
        t = browser.categoryData;
        a = browser.accomods;

        /// CATEGORIZE ACCOMODS
        accomodCats.forEach( (ac:any) => {
            let accoList:any = [];
            a.forEach( (doc:any) => {
                if (doc.Key.substr(0,1) === ac.key) {
                    accoList.push(doc);
                }
            });
            accomods.push({ category: ac.category, docs: accoList });
        });
    }

    return (
        docsLoaded ?
            <div>
                <h2 className={'blue'}>{t.Name}</h2>
                <div dangerouslySetInnerHTML={{__html: t.Description}}></div>

                { accomods.map( (s:any, i:number) =>
                    <div className='acco-select-block' key={'accoCat-'+i}>
                        <h4 className='select-table-title green'>{ s.category }</h4>
                        <SelectTable data={ s.docs } showCartActions={true} emptyMessage={'None Suggested'} />
                    </div>
                )}

                <h4 className={'blue'}>Considerations for learning impact:</h4>
                <div dangerouslySetInnerHTML={{__html: t.Considerations}}></div>
                <br/>

                <h4 className={'blue'}>Skill Building Resources:</h4>
                <div dangerouslySetInnerHTML={{__html: t.Resources}}></div>
                <br/>

                <h4 className={'blue'}>Accommodation and Modification Consideration Process:</h4>
                <div dangerouslySetInnerHTML={{__html: t.Process}}></div>
            </div>
            :
            <><h2 className='green centered'>Fetching Accommodations</h2>
                <div className="gears"><img src={gears} alt="loading gears" className="" /></div>
            </>
    );
};

const mapStateToProps = (state: any) => ({
    browser: state.browser
});

export default connect(mapStateToProps, {})(BrowserTopicDetails);
