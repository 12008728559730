import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import kids1 from '../../shared/img/kids1.png';
import store from '../../store';
import { loadDashMessages, loadNeeds, loadStrengths } from '../../redux/actions/data';
import { connect } from 'react-redux';
import { addNeed, removeNeed } from '../../redux/actions/builder';
import { openModal } from '../../redux/actions/modal';
import DashboardMessages from '../../components/DashboardMessages';

const Dashboard = (props: any) => {

    const { auth, profile, loadDashMessages, loadNeeds, loadStrengths } = props;

    const fname = profile && profile.Name && profile.Name.First ? profile.Name.First : null;

    const hour = new Date().getHours();
    let timeOfDay = 'Morning';
    if (hour >= 12 && hour <= 17) timeOfDay = 'Afternoon';
    if (hour >= 18 && hour <= 23) timeOfDay = 'Evening';
    if (hour >= 0 && hour <= 2) timeOfDay = 'Evening';

    useEffect(() => {
        (async () => {
            await loadNeeds();
            await loadStrengths();
            await loadDashMessages();
        })();
    }, [auth])

    return (
        <Container className="dashboard">
            <Row>
                <Col md={12} className="welcome">
                    <h1 className="blue hXL">Good {timeOfDay}{ fname ? ', '+fname : ''}!</h1>
                    <p>Welcome back to Accomods.  We’re excited to be your partner as you work to explore, understand, and implement  “just-right” adaptations for your students.  As always, thank you for everything you do to empower an effective education for all children!</p>
                </Col>
            </Row>
            <Row>
                <div className='d-none d-lg-block col-lg-3'>
                    <img src={kids1} alt="students" className="img-fluid"/>
                </div>
                <Col lg={9} md={12}>
                    <Row>
                        <Col md={12}><h2 className="green getstarted">Let's Get Started...</h2></Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div className={'acco-functions'}>
                                <h4 className="green"><i
                                    className="fa-duotone fa-solid fa-screwdriver-wrench"></i> Customize a Plan</h4>
                                <p>Our Accomods Builder will help you align your student’s strengths and learning needs to our list of hundreds of adaptations to create a plan custom-fit to your student.</p>
                            <p><i className="fa-solid fa-circle-right fa-fw blue"></i> <Link to="/builder/strengths">Start Building</Link></p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div  className={'acco-functions'}>
                                <h4 className="green"><i className="fa-duotone fa-solid fa-bookmark"></i> Browse By
                                    Topic</h4>
                                <p>We’ve organized our database of accommodations and modifications by learning need to help you quickly narrow down those that suit your student's needs.</p>
                            <p><i className="fa-solid fa-circle-right fa-fw blue"></i> <Link to="/browser/topics">Dive into Topics</Link></p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div  className={'acco-functions'}>
                                <h4 className="green"><i className="fa-duotone fa-solid fa-magnifying-glass"></i> Search
                                    Accomods</h4>
                                <p>Looking for a specific adaptation? Use our full-text search engine to find plans by keyword.</p>
                            <p><i className="fa-solid  fa-circle-right fa-fw blue"></i> <Link to="/search">Begin Your Search</Link></p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DashboardMessages />
                </Col>
            </Row>
        </Container>
    );
};


const mapStateToProps = (state: any) => ({
    auth: state.auth,
    profile: state.profile.profile
});

export default connect( mapStateToProps, { addNeed, removeNeed, openModal, loadDashMessages, loadNeeds, loadStrengths })(Dashboard);

