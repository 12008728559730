import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import BuilderNav from './BuilderNav';
import kids2 from '../../shared/img/kids2.png';
import { connect } from 'react-redux';
import { setNeedPriorities } from '../../redux/actions/builder';

const BuilderPrioritize = (props: any) => {

    const { builder, setNeedPriorities } = props;

    const categoryList = ['Learning Needs', 'Intellectual Needs', 'Executive Function Needs', 'Social / Emotional / Behavioral Needs'];

    const categoryHasItems = ( catName:string ) => {
        let out = false;
        builder.needs.map( (n:any) => {
            if (n.Category === catName) out = true;
        });
        return out;
    };

    const changePriority = ( n:any, priority:number ) => {
        const newNeeds = builder.needs.map((need:any) => {
            if (need.id === n.id) {
                need.priority = priority;
            }
            return need;
        });
        setNeedPriorities(newNeeds);
    };

    const deleteNeed = ( needId:string ) => {
        const yesno = window.confirm('Are you sure you want to delete this need?');
        if (yesno) {
            const newNeeds = builder.needs.filter((need:any) => need.id !== needId);
            setNeedPriorities(newNeeds);
        }
    };

    return (
        <>
            <PageTitle title='Accomods Builder' browse={true} search={true} />

            <Container>
                <Row>
                    <Col sm={12}>
                        <div className='acco-builder'>
                            <div className='acco-builder-nav'>
                                <BuilderNav step={3} />
                                <img src={kids2} alt="students" className="" />
                            </div>
                            <div className='acco-builder-body'>
                                <h2 className='green'>Prioritize your student’s needs:</h2>
                                <p>The needs of an individual student are not all equal. Try to prioritize the needs you’ve selected
                                    for this student by High, Medium and Low.</p>

                                {  builder.needs.length > 0 ?
                                    categoryList.map( (catName:any, i:number) => {
                                        if (categoryHasItems( catName )) {
                                            return (
                                                <div className='acco-select-block' key={'catsection-'+i}>
                                                    <h4 className='acco-select-block-title2 blue'>{ catName }</h4>
                                                    <div className='acco-select-block-prioritize'>
                                                        { builder.needs.map( (p:any) => {
                                                            if ( p.Category === catName) {
                                                                return (
                                                                    <div className='acco-priority' key={p.id}>
                                                                        <div className='acco-priority-trash'><i className="fa-duotone fa-solid fa-trash-can" onClick={()=>{deleteNeed(p.id)}}></i></div>
                                                                        <div className='acco-priority-name'>{ p.Name }</div>
                                                                        <div className='acco-priority-setting high'>
                                                                            <label className={(p.priority===1.5 ? 'priority-high' : '')}>
                                                                                <input type='radio' name={p.id} defaultChecked={p.priority===1.5} onChange={()=>{changePriority(p, 1.5)}} /> High
                                                                            </label>
                                                                        </div>
                                                                        <div className='acco-priority-setting medium'>
                                                                            <label className={(p.priority===1 ? 'priority-med' : '')}>
                                                                                <input type='radio' name={p.id} defaultChecked={p.priority===1} onChange={()=>{changePriority(p, 1)}} /> Medium
                                                                            </label>
                                                                        </div>
                                                                        <div className='acco-priority-setting low'>
                                                                            <label className={(p.priority===0.5 ? 'priority-low' : '')}>
                                                                                <input type='radio' name={p.id} defaultChecked={p.priority===0.5} onChange={()=>{changePriority(p, 0.5)}} /> Low
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }}
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })
                                    :
                                    <div>No needs defined.</div>
                                }
                                {/*<div className='acco-select-block'>*/}
                                {/*    <h3 className='acco-select-block-title blue'>Custom Needs</h3>*/}
                                {/*    <div className='acco-select-block-prioritize'>*/}

                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className='acco-builder-flowbtn'>
                            <Link to='/builder/select'
                                  className={'btn btn-pill btn-lg btn-primary' + (builder.needs.length === 0 ? ' disabled' : '')}>
                                Next Step <i className="fa-regular fa-angles-right fa-lg"></i>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    builder: state.builder
});

export default connect( mapStateToProps, { setNeedPriorities })(BuilderPrioritize);

