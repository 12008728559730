import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Navigate, useLocation } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import axios from 'axios';
import { accoApiUrl } from '../../services/helpers';
import { connect } from 'react-redux';
import { setAlert } from '../../redux/actions/alert';
import moment from 'moment';
import { login } from '../../redux/actions/auth';
import Topbar from '../Layout/topbar/Topbar';

const RegisterComplete = (props: any) => {

    const { auth, setAlert, login } = props;

    let location = useLocation();

    const initUserAccount: any = {
        fname: '',
        lname: '',
        email: '',
        id: ''
    };

    const [loading, setLoading] = useState(true);
    const [userAccount, setUserAccount] = useState(initUserAccount);
    const [pw, setPw] = useState({ pw1:'', pw2:''});
    const [submitting, setSubmitting] = useState( false );
    const [matchError, setMatchError] = useState( false );
    const [submitError, setSubmitError] = useState( false );
    const [userFound, setUserFound] = useState( false );

    const getUserAccount = async () => {
        let params = location.search.substring(1);
        let parsed = JSON.parse('{"' + decodeURI(params).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        let userEmail = decodeURIComponent(parsed[ "thrivecart[customer][email]" ]);

        let data = { email: userEmail };
        const acct = await axios.post(accoApiUrl() + '/thrive/lastuser', data);

        console.log('user', acct.data);
        if (acct.data.success) {
            setUserAccount(acct.data.payload);
            setUserFound(true);
        }
        setLoading(false);
    }

    const pwChange = ( field:string, value:string ) => {
        let tmpPw:any = { ...pw };
        tmpPw[field] = value;
        setPw( tmpPw );
    }

    const submitPassword = async () => {
        setSubmitting( true );
        if (pw.pw2 !== pw.pw1) {
            setMatchError( true );
        }
        else {
            const pwData = { 'userId': userAccount.id, 'newPw': pw.pw1 };
            const res = await axios.post(accoApiUrl() + '/thrive/setpw', pwData);
            if (res.data.success) {
                login({ userOrEmail: userAccount.email, password: pw.pw1 });
            } else {
                setSubmitError( true );
            }
        }
        setSubmitting( false );
    }

    useEffect(() => {
        if (loading) {
            getUserAccount();
        }
    }, [loading]);

    console.log('location', location);

    const hasToken = auth && auth.access_token;

    return (hasToken ? <Navigate to="/dashboard" /> :
            <>
                <Topbar />
                { userFound ?
                    <Container className={'acco-register'}>
                        <Row>
                            <Col md={12}>
                                <PageTitle title='Complete Your Registration'
                                           subWidth='full'
                                           subtitle={'Thank you for creating your account through our ThriveCart payment system. To complete your account, please set a password using the fields below. '}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className='complete-label'>Your Name:</td>
                                        <td className='complete-value'>{userAccount.fname} {userAccount.lname}</td>
                                    </tr>
                                    <tr>
                                        <td className='complete-label'>Email Address:</td>
                                        <td className='complete-value'>{userAccount.email}</td>
                                    </tr>
                                    <tr>
                                        <td className='complete-label'>Registration Date: &nbsp;&nbsp;&nbsp;&nbsp;</td>
                                        <td className='complete-value'>{moment(userAccount.date_registered).format('MMMM d, YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <td className='complete-label'>&nbsp;</td>
                                        <td className='complete-value'>&nbsp;</td>
                                    </tr>
                                    </tbody>
                                </table>

                                <br/>
                                <div className='form-group'>
                                    <label className='complete-label'>Set Password</label>
                                    <input type='password' className='form-control' value={pw.pw1} onChange={(e:any)=>pwChange('pw1', e.target.value)} />
                                </div>
                                <div className='form-group'>
                                    <label className='complete-label'>Confirm Password</label>
                                    <input type='password' className='form-control' value={pw.pw2} onChange={(e:any)=>pwChange('pw2', e.target.value)} />
                                </div>
                                { matchError &&
                                  <div className='form-group red'>
                                    <i className="fa-solid fa-triangle-exclamation"></i> Passwords do not match
                                  </div>
                                }
                                { submitError &&
                                  <div className='form-group red'>
                                    <i className="fa-solid fa-triangle-exclamation"></i> There was an error while
                                    submitting your password.
                                  </div>
                                }
                                <div className='form-group'>
                                    <button className='btn btn-primary' onClick={()=>submitPassword()} disabled={submitting}>Complete Registration</button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    :
                    <Container className={'acco-register'}>
                        <Row>
                            <Col md={12}>
                                <PageTitle title='Invalid Key'
                                           subWidth='full'
                                           subtitle={'This password URL is invalid. It is probable that the password has already been set up for this account. If you believe this is an error, please contact support at admin@actiondriveneducation.com.'}/>
                            </Col>
                        </Row>
                    </Container>
                }
            </>
    );
};

const mapStateToProps = (state: any) => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert, login })(RegisterComplete);

