import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import BuilderNav from './BuilderNav';
import kids2 from '../../shared/img/kids2.png';
import { addNeed, removeNeed } from '../../redux/actions/builder';
import { connect } from 'react-redux';
import { openModal } from '../../redux/actions/modal';

const BuilderNeeds = (props: any) => {

    const { data, builder, addNeed, removeNeed, openModal } = props;

    const defaultCustomNeeds = [
        { Name: '', __i: { guid: 'cn000' }},
        { Name: '', __i: { guid: 'cn001' }},
        { Name: '', __i: { guid: 'cn002' }},
        { Name: '', __i: { guid: 'cn003' }},
        { Name: '', __i: { guid: 'cn004' }},
        { Name: '', __i: { guid: 'cn005' }}
    ];

    if (builder && builder.needs) {
        builder.needs.forEach((cn:any)=>{
            if (cn.__i.guid.includes('cn00')) {
                let thisId = cn.__i.guid.substr(4,1);
                defaultCustomNeeds[thisId].Name = cn.Name;
            }
        });
    }

    const [customNeeds, setCustomNeeds] = useState( defaultCustomNeeds );

    const toggleNeed = ( need:any, category:string, id:string ) => {
        need.Category = category;
        need.id = id;
        need.priority = 1;

        if (isInNeeds(need)) {
            removeNeed(need);
        } else {
            addNeed(need);
        }
    }

    const isInNeeds = ( need:any ) => {
        let isChecked = false;
        builder.needs.map( (n:any) => {
            //console.log( n.id, need.__i.guid );
            if ( n.__i.guid === need.__i.guid ) {
                isChecked = true;
            }
        });
        return isChecked;
    }

    const setCustomNeed = ( i:number, value:string ) => {
        let cn = [ ...customNeeds ];
        cn[i].Name = value;
        setCustomNeeds( cn );
    }

    const submitCustomNeeds = () => {
        customNeeds.map( (cn:any, i:number) => {
            removeNeed(cn);
            if (cn.Name.length) {
                addNeed(cn);
            }
        });
    }

    const builderNeeds = data && data.needs && data.needs.length ? data.needs : [];

    return (
        <>
            <PageTitle title='Accomods Builder' browse={true} search={true} />

            <Container>
                <Row>
                    <Col sm={12}>
                        <div className='acco-builder'>
                            <div className='acco-builder-nav'>
                                <BuilderNav step={2} />
                                <img src={kids2} alt="students" className="" />
                            </div>
                            <div className='acco-builder-body'>
                                <h2 className='green'>Identify your student's needs:</h2>
                                <p>When you think about your student, identify the need(s) below that impact his or her learning.  Click on a need to learn more about it. </p>

                                { builderNeeds.map( (s:any) =>
                                    <div className='acco-select-block' key={s.__i.guid}>
                                        <h3 className='acco-select-block-title blue'>{ s.Name }</h3>
                                        <div className='acco-select-block-flex'>
                                            { s.Needs.map( (t:any) =>
                                                <div key={t.__i.guid}>
                                                    <input type='checkbox' checked={isInNeeds(t)} onChange={()=>toggleNeed(t, s.Name, t.__i.guid)} />
                                                    <span className="traitClick" onClick={()=>openModal(t.__i.guid, 'small')}>{ t.Name }</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className='acco-select-block'>
                                    <h3 className='acco-select-block-title blue'>Add Your Own Needs</h3>
                                    <div className='acco-select-block-flex'>
                                        { customNeeds.map( (cn:any, i:number ) =>
                                            <div key={cn.__i.guid}>
                                                <label>
                                                    <input type='checkbox' checked={cn.Name.length > 0}/>
                                                    <span>
                                                    <input type='text'
                                                           className="form-control"
                                                           value={cn.Name}
                                                           onChange={(e:any)=>setCustomNeed(i, e.target.value)}
                                                           onBlur={(e:any)=>submitCustomNeeds()} />
                                                </span>
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='acco-builder-flowbtn'>
                            <Link to='/builder/prioritize'
                                  className={'btn btn-pill btn-lg btn-primary' + (builder.needs.length === 0 ? ' disabled' : '')}>
                                Next Step <i className="fa-regular fa-angles-right fa-lg"></i>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    builder: state.builder,
    data: state.data,
});

export default connect( mapStateToProps, { addNeed, removeNeed, openModal })(BuilderNeeds);

