import React from 'react';


export const formError = (str: string) => {
    return <p className={`form-error red`}><i className="fa-solid fa-triangle-exclamation"></i> &nbsp;{str}</p>;
};

export const filterEntity = (entitySet: any, filterBy: string, searchValue: string) => {
    let x = entitySet.filter((c: any) => {
        return c[ filterBy ] === searchValue
    });
    return x.length > 0 ? x[ 0 ] : null;
};

export const mapClass = ( value: number, otherError: boolean ) => {
    let classOut = '';
    switch (value) {
        case -1: classOut = ''; break;
        case 0:  classOut = 'loading'; break;
        case 1:  classOut = 'good'; break;
        case 2:  classOut = 'bad'; break;
        default: classOut = ''; break;
    }
    return otherError ? 'bad' : classOut;
};

export const checkProfileComplete = (p: any) => {
    if ((!p.firstName || p.firstName.length === 0) || (!p.lastName || p.lastName.length === 0) || !p.birthDate || !p.gender) {
        return false;
    } else {
        return true;
    }
};

export const convertToDate = ( date: string, time: string ) => {
    //2020-01-29 17:27
    let d = date.split('-');
    let t = time.split(':');
    let newDate = new Date(parseInt(d[0]), parseInt(d[1])-1, parseInt(d[2]), parseInt(t[0]), parseInt(t[1]), 0, 0);
    return newDate.toUTCString();
};

export const convertToUnix = ( date: string, time: string ) => {
    //2020-01-29 17:27
    let d = date.split('-');
    let t = time.split(':');
    let newDate = new Date(parseInt(d[0]), parseInt(d[1])-1, parseInt(d[2]), parseInt(t[0]), parseInt(t[1]), 0, 0);
    return Math.round(newDate.getTime() / 1000);
};

export const convertToLocal = ( date: string ) => {
    //2020-02-02T17:20:00.000Z
    let d = date.substr(0,10).split('-');
    let t = date.substr(11,5).split(':');
    let newDate = new Date(parseInt(d[0]), parseInt(d[1])-1, parseInt(d[2]), parseInt(t[0]), parseInt(t[1]), 0, 0);
    const offset = newDate.getTimezoneOffset();
    newDate.setMinutes(newDate.getMinutes() - offset);
    return newDate.toString();
}

export const lastItem = (array: any ) => {
    if (array === null)
        return void 0;
    else
        return array[array.length - 1];
};

