import {LOAD_DASH_MSGS, LOAD_NEEDS_CATS, LOAD_STRENGTH_CATS} from '../types';
import axios from "axios";
import {apiURL} from "../../services/helpers";

export const loadNeeds = () => async dispatch => {
  try {
    const res = await axios.get(apiURL()+'/NeedsParent?action=get&i=0&size=10');
    dispatch({
      type: LOAD_NEEDS_CATS,
      payload: res.data.r
    });
  } catch(err) {
    console.log('error loading needs');
  }
}


export const loadStrengths = () => async dispatch => {
  try {
    const res = await axios.get(apiURL()+'/StrengthsParent?action=get&i=0&size=10');
    dispatch({
      type: LOAD_STRENGTH_CATS,
      payload: res.data.r
    });
  } catch(err) {
    console.log('error loading strengths');
  }
}


export const loadDashMessages = () => async dispatch => {
  console.log('loadDashMessages');
  try {
    const q = encodeURIComponent("{ 'Payload.IsActive': true }");
    const url = apiURL()+'/DashboardMessage?size=25&i=0&q='+q+'&t=&sort=asc';
    const res = await axios.get( url );
    console.log(res.data.r);
    dispatch({
      type: LOAD_DASH_MSGS,
      payload: res.data.r
    });
  } catch(err) {
    console.log('error loading strengths');
  }
}
