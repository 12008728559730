import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Link, useParams, useRoutes } from 'react-router-dom';
import logo from '../../shared/img/logo.png';
import { accoApiUrl } from '../../services/helpers';
import axios from 'axios';
import { Col, Container, Row } from 'reactstrap';

const PwResetForm = ( props:any ) => {
    const { match } = props;

    const params = useParams();
    const [submitting, setSubmitting] = useState( false );
    const [success, setSuccess] = useState( -1 );
    const [formData, setFormData] = useState({
        password: '',
    });
    const [showPw, setShowPw] = useState( false );

    const pwHash = params.hash;

    const submitForm = async () => {
        setSubmitting(true);
        let tmpData:any = { ...formData };
        tmpData.reset_hash = pwHash;

        try {
            const res = await axios.post( accoApiUrl()+'/auth/pwresetsubmit', tmpData );

            if (res.data.success) {
                setSuccess(1);
            } else {

            }
        } catch(err) {
            setSubmitting(false);
        }
    }

    return (
        <div className="cmt-login auth-form">
            <div className="cmt-login-inner">
                <Container>
                    <Row>
                        <Col lg={{ size: 8, offset: 2 }}>
                            <div className="cmt-login__title">
                                <img src={logo} alt="Accomods Logo" className="logo"/>
                            </div>
            { success !== 1 ?
                <div className='pf-login-panel'>
                    <h1>Reset Your Password</h1>
                    <p>Enter your new password below.<br/>Tap/Click the lock icon to show or hide your password. <br/>&nbsp;</p>

                    <div className={`form-group`}>
                        <label>New Password</label>
                        <div className="auth-form-group">
                            <div className="ico-left"><i className="fa-solid fa-key"></i></div>
                            <input
                                name="password"
                                value={formData.password}
                                type={showPw ? 'text' : 'password'}
                                onChange={(e: any) => setFormData({ ...formData, password: e.target.value })}
                                minLength={6}
                                className="form-control input-ico-left input-ico-right"
                                required
                            />
                            <div className="ico-right"
                                 onClick={()=>setShowPw(!showPw)}>
                                <i className={`fas ` + (showPw ? 'fa-lock-open-alt' : 'fa-lock-alt')}></i>
                            </div>
                        </div>
                    </div>


                    <button className="btn btn-success"
                            style={{ marginTop: '16px', paddingLeft: '60px', paddingRight: '60px' }}
                            onClick={() => submitForm()}
                            disabled={submitting}>&nbsp; &nbsp; Submit &nbsp; &nbsp;</button>
                    <br/><br/>
                </div>
                :
                <div className='pf-login-panel'>
                    <div className="alert alert-success">
                        <b style={{ fontSize: '20px' }}>Password Reset Successfully!</b>
                    </div>
                    <br/>
                    <p style={{ fontSize: '16px' }}>Please continue to the login screen to sign in with your new password.</p>
                    <br/><br/>
                    <Link to={"/"} className="btn btn-primary" style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                        Continue to Login
                    </Link>
                </div>
            }

                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(PwResetForm);

