import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

const DashboardMessages = (props: any) => {
    const { messages } = props;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(true);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    // Start the automatic cycling of messages
    const startAutoCycle = () => {
        if (messages.length > 1) {
            stopAutoCycle(); // Clear any existing interval before starting a new one
            intervalRef.current = setInterval(() => {
                setFade(false); // Trigger fade-out
                setTimeout(() => {
                    setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
                    setFade(true); // Trigger fade-in
                }, 500); // Wait 500ms for the fade-out transition to complete
            }, 5000);
        }
    };

    // Stop the automatic cycling of messages
    const stopAutoCycle = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    useEffect(() => {
        if (messages.length > 1) {
            startAutoCycle();
        }
        return () => {
            if (messages.length > 1) {
                stopAutoCycle();
            }
        } // Clean up the interval on component unmount
    }, [messages.length]);

    // Handle dot click
    const handleDotClick = (index: number) => {
        setFade(false); // Trigger fade-out
        setTimeout(() => {
            setCurrentIndex(index);
            setFade(true); // Trigger fade-in
            startAutoCycle(); // Restart the timer after clicking
        }, 500); // Wait for the fade-out transition to complete
    };

    return (
        <>
            {messages.length >= 1 ?
            <div
                className="dash-messages"
                onMouseEnter={stopAutoCycle} // Pause on hover
                onMouseLeave={startAutoCycle} // Resume on leave
            >
                {messages.map((msg: any, index: number) => (
                    <div
                        key={index}
                        className={`dash-message ${currentIndex === index ? (fade ? 'fade-in' : 'fade-out') : 'hidden'}`}
                    >
                        <div className="dash-message-icon">
                            <i className={`fa-duotone fa-solid fa-fw ${msg.Icon}`}></i>
                        </div>
                        <div className="dash-message-body">
                            <h1>{msg.Title}</h1>
                            <div dangerouslySetInnerHTML={{ __html: msg.Message }}></div>
                            <p>
                                <a href={msg.LinkUrl} target="_blank" rel="noopener noreferrer">
                                    {msg.LinkText}
                                </a>{' '}
                                <i className="fa-solid fa-circle-right fa-fw blue"></i>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
                :
                <></>
            }
            {messages.length > 1 ?
                <div className="carousel-dots">
                    {messages.map((_: any, index: number) => (
                        <div
                            key={index}
                            className={`dot ${currentIndex === index ? 'active' : ''}`}
                            onClick={() => handleDotClick(index)} // Click event to navigate to the specific message
                        ></div>
                    ))}
                </div>
                :
                <></>
            }

        </>
    );
};

const mapStateToProps = (state: any) => ({
    messages: state.data.messages,
});

export default connect(mapStateToProps, {})(DashboardMessages);
