import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import { connect } from 'react-redux';
import { searchAccomods } from '../../redux/actions/search';
import SelectTable from '../../components/SelectTable';
import gears from '../../shared/img/gears.gif';

const Search = (props: any) => {

    const { search, searchAccomods } = props;
    const [ searchTerm, setSearchTerm ] = useState( '' );

    //const hasResults = search && !search.isLoading;

    const execSearch = (query: any) => {
        setSearchTerm( query.target.value );

    }

    const clearSearch = () => {
        setSearchTerm( '' );
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            console.log(searchTerm)
            // Send Axios request here
            if (searchTerm.length >= 3) {
                searchAccomods(searchTerm);
            }
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm, searchAccomods])

    return (
        <>
            <PageTitle title='Search Accomods'
                       subtitle={'Search uses a full-text search engine to find accommodations and modifications by a single keyword.'}
                       builder={true} browse={true} search={false}/>
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="search-bar-div">
                            <input className="search-bar"
                                   type='text'
                                   placeholder="Enter search term"
                                   name="searchbox"
                                   value={searchTerm}
                                   onChange={(val: any) => execSearch(val)}
                            />
                            <i className="fa-solid fa-search fa-fw fa-flip-horizontally"></i>
                        </div>
                        <br/>
                        {   (search.isLoading === null || searchTerm.length === 0) ?
                                <div></div>
                            :
                            search.isLoading === true ?
                                <div className="gears"><img src={gears} alt="loading gears" className="" /></div>
                            :
                            search.searchResults.length ?
                                <div>
                                    <p>
                                        <span className="clearSearch" onClick={() => clearSearch()}>
                                            <i className='fas fa-times-circle'></i> Clear Search</span>
                                        <b>{search.searchResults.length} Matching Accommodations</b>
                                    </p>
                                    <p>&nbsp;</p>
                                    <SelectTable data={search.searchResults} showCartActions={true} />
                                </div>
                            :
                            <div>No results.</div>
                        }
                        <br/><br/>
                        <Link to='/dashboard' className='btn btn-pill btn-primary'>
                            <i className="fa-regular fa-angles-left fa-lg"></i> &nbsp;Back to Dashboard</Link>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    search: state.search
});

export default connect(mapStateToProps, { searchAccomods })(Search);

