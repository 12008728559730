import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import BuilderNav from './BuilderNav';
import kids2 from '../../shared/img/kids2.png';
import gears from '../../shared/img/gears.gif';

import SelectTable from '../../components/SelectTable';
import { connect } from 'react-redux';
import { getMatchingAccomods } from '../../redux/actions/builder';



const BuilderSelect = (props: any) => {

    const { data, builder, getMatchingAccomods } = props;

    const accomods:any = [];
    const accomodCats:any = [
        { category: 'Behavioral', key: 'B' },
        { category: 'Curriculum', key: 'C' },
        { category: 'Learning', key: 'L' },
        { category: 'Assessing', key: 'A' }
    ];

    if (builder && builder.accomodsLoading) {
        getMatchingAccomods( builder.needs, builder.strengths );
    }

    if (builder && !builder.accomodsLoading) {

        /// CATEGORIZE ACCOMODS
        accomodCats.forEach( (ac:any) => {
            let accoList:any = [];
            builder.accomods.forEach( (doc:any) => {
                if (doc.Key.substr(0,1) === ac.key) {
                    accoList.push(doc);
                }
            });
            accomods.push({ category: ac.category, docs: accoList });
        });
    }

    return (
        <>
            <PageTitle title='Accomods Builder' browse={true} search={true} />

            <Container>
                <Row>
                    <Col sm={12}>
                        <div className='acco-builder'>
                            <div className='acco-builder-nav'>
                                <BuilderNav step={4} />
                                <img src={kids2} alt="students" className="" />
                            </div>
                            <div className='acco-builder-body'>
                                {
                                    builder && builder.accomodsLoading ?
                                        <><h2 className='green centered'>Calculating Matching Accommodations</h2>
                                        <div className="gears"><img src={gears} alt="loading gears" className="" /></div>
                                        </>
                                    :
                                        <><h2 className='green'>Review and Select Recommended Accommodations</h2>
                                            <p>Based on the strengths and needs you've selected, the following is a list of accommodations and modifications you may wish to consider.  Click on an accommodation to learn more about it.  Accomods provides a starting point designed to get you thinking of unique ways to support your student.  Follow your thoughts as you consider these accommodations in order to develop the perfect solution.  What accommodations do you see that may help?  Add these to your plan.  What new ideas come to your mind?  Follow these ideas to develop unique solutions.  </p>

                                            { accomods.map( (s:any, i:number) =>
                                                <div className='acco-select-block' key={'accoCat-'+i}>
                                                    <h3 className='select-table-title blue'>{ s.category }</h3>
                                                    <SelectTable data={ s.docs } showCartActions={true} emptyMessage={'None Selected'} category={s.category.toLowerCase()} catLimit={20} />
                                                </div>
                                            )}
                                        </>
                                }
                            </div>
                        </div>
                        <div className='acco-builder-flowbtn'>
                            { (builder && !builder.accomodsLoading) &&
                              <Link to='/builder/export' className='btn btn-pill btn-lg btn-primary'>Next Step <i className="fa-regular fa-angles-right fa-lg"></i></Link>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>


        </>
    );
};


const mapStateToProps = (state: any) => ({
    builder: state.builder,
    data: state.data,
});

export default connect( mapStateToProps, { getMatchingAccomods })(BuilderSelect);
