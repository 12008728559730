export const apiURL = () => {
    // USE WHEN READY FOR V2 API
    return '/api/v2/objects/data';

    // const theURL:string = document.location.href;
    //
    // if ( theURL.includes('localhost') ) {
    //     return 'https://admin.inmotionapptest.net/api/v1/e/api';
    // }
    // else if ( theURL.includes('dev.accomods.com') ) {
    //     return 'https://admin.inmotionapptest.net/api/v1/e/api';
    // }
    // else if ( theURL.includes('accomods.com') ) {
    //     return 'https://admin.inmotionapp.net/api/v1/e/api';
    // }
}

export const accoApiUrl = () => {
    const theURL:string = document.location.href;

    if ( theURL.includes('localhost') ) {
        return 'https://api.accomods.com/v1';
    }
    else if ( theURL.includes('dev.accomods.com') ) {
        return 'https://api.accomods.com/v1';
    }
    else if ( theURL.includes('accomods.inmotionapptest.net') ) {
        return 'https://api.accomods.com/v1';
    }
    else if ( theURL.includes('accomods.com') ) {
        return 'https://api.accomods.com/v1';
    }
    else if ( theURL.includes('accomods.inmotionapp.net') ) {
        return 'https://api.accomods.com/v1';
    }
}


export const isAdmin = ( userName:string ) => {
    const admins = [
        'awaldron@buildinmotion.com',
        'timkretchman@actiondriveneducation.com',
        'aprilkretchman@actiondriveneducation.com'
    ];
    return admins.includes(userName);
}

    //Find all Selected Needs
    /*For each accomod in cart:
    Find the matching accomod in builder.accomds
    Pull out the needs of that accomod*/

    //Pull out all Needs of Accomods matching those in the cart
    //Match Accomod Needs to those Selected by the User

// export const monitorFormAlgorithm = ( cart:any, needs:any, accomods:any  ) => {
//     let monitorOut:any = [{ id: "", title: "", needs:[] }];
//     let accomodNeeds:any = [];
//     let filteredList:any = [];
//
//     console.log('SELECTED NEEDS', {needs});
//     console.log('CART', {cart});
//
//     function findMatchingNeeds () {
//             accomodNeeds.map( (a:any, num:number) => {
//                 a.map( (an:any, num:number) => {
//                     needs.map( (n:any) => {
//                         //console.log('Accomod Needs:', an.Name);
//                         //console.log('Selected Needs:', n.Name);
//                         if (n.Name === an.Name) {
//                             filteredList.push(an.Name);
//                             console.log('MATCHED A NEED!:', an.Name);
//                         }
//                     });
//                 });
//                 //monitorOut[num].needs = filteredList;
//                 monitorOut.needs = filteredList;
//                 filteredList = [];
//             });
//         //return filteredList;
//         return;
//     }
//
//     cart.map( (c:any, num:number) => {
//         console.log('Cart items:', c.Key);
//         //monitorOut[num].id = c.Key;
//         //monitorOut[num].title = c.Title;
//         monitorOut.id = c.Key;
//         monitorOut.title = c.Title;
//
//         accomods.map( (a:any) => {
//             if (a.Key === c.Key) {
//                 console.log('Accomod Needs:', a.NeedsCategories);
//                 accomodNeeds.push(a.NeedsCategories);
//             }
//         });
//     });
//     console.log('ALL ACCOMOD NEEDS', {accomodNeeds});
//     accomodNeeds.filter(findMatchingNeeds);
//
//     return monitorOut;
// }

export const monitorFormAlgorithm = ( cart:any, needs:any, accomods:any  ) => {

    // Create an array of selected Accomods with full data
    const cartFullData:any = [];
    cart.forEach( ( cartAcco:any ) => {
        accomods.forEach( (fullAcco:any) => {
            if (fullAcco.Key === cartAcco.Key) {
                cartFullData.push(fullAcco);
            }
        });
    });

    // Loop through cartFullData and filter out unselected needs
    const out:any = cartFullData.map( (acco:any) => {
        const itemOut:any = { id: acco.__i.guid, key: acco.Key, title: acco.Title, needs:[] };

        // Determine which needs to show
        const accoNeeds:any = [];
        needs.forEach( ( need:any ) => {
            acco.NeedsCategories.forEach( ( thisNeed:any ) => {
                if (thisNeed.__i.guid === need.__i.guid) {
                    accoNeeds.push( need.Name );
                }
            });
        });
        itemOut.needs = accoNeeds;
        return itemOut;
    });

    return out;
}
