import { CLEAR_DATA, LOAD_NEEDS_CATS, LOAD_STRENGTH_CATS, LOAD_DASH_MSGS } from '../types';

const initialState:any = {
    needs: [],
    strengths: [],
    messages: [],
};

export default function(state = initialState, action:any) {
    const { type, payload } = action;

    switch(type) {
        case LOAD_NEEDS_CATS:
            return {
                ...state,
                needs: payload
            }
        case LOAD_STRENGTH_CATS:
            return {
                ...state,
                strengths: payload
            }
        case LOAD_DASH_MSGS:
            console.log('dash msgs', payload);
            return {
                ...state,
                messages: payload
            }
        case CLEAR_DATA:
            return initialState;
        default:
            return state;
    }
}
